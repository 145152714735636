<template>
  <main class="auth login">
    <div class="login__background"></div>
    <section class="login__form">
      <div class="login__loader"></div>
      <div v-if="validationErrors.length" class="login__errors">
        <div>
          <div
            v-for="(error, errorIndex) in validationErrors"
            :key="`error-${errorIndex}`"
            v-html="error"
          ></div>
        </div>
      </div>

      <div class="login__form-step">
        <img src="../assets/images/logo.svg" :alt="`${$companyName} logo`" />

        <h2 class="login__form-title">
          {{ $languages[getAppLanguage].logIn.title }}
        </h2>
        <p>
          {{ $languages[getAppLanguage].logIn.sub }}
        </p>
        <br />

        <form>
          <label for="email">
            <span>
              {{ $languages[getAppLanguage].logIn.emailLabel }}
            </span>
            <input
              type="email"
              v-model="email"
              id="email"
              :placeholder="$languages[getAppLanguage].logIn.emailPlaceholder"
            />
          </label>

          <label for="passwrod">
            <span>
              {{ $languages[getAppLanguage].logIn.passwordLabel }}
            </span>
            <input
              type="password"
              v-model="password"
              id="passwrod"
              :placeholder="
                $languages[getAppLanguage].logIn.passwordPlaceholder
              "
              autocomplete="true"
            />
          </label>

          <section class="login__form-checkbox-forgot-password-wrapper">
            <Checkbox @checkbox-check="getCheckboxState" :checked="rememberMe">
              {{ $languages[getAppLanguage].logIn.remember }}
            </Checkbox>

            <router-link
              class="login__form-forgot-password"
              to="/reset-password"
            >
              {{ $languages[getAppLanguage].logIn.forgotPassword }}
            </router-link>
          </section>

          <Button @button-clicked="validateLogin" icon="translate-main" submit>
            {{ $languages[getAppLanguage].logIn.logIn }}
          </Button>

          <div class="login__form-divider"></div>
          <router-link to="/signup">
            {{ $languages[getAppLanguage].logIn.signUp }}
          </router-link>
          <br />
          <br />
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// Components
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'

export default {
  name: 'Login',

  data: () => ({
    email: null,
    password: null,
    rememberMe: null,
    validationErrors: []
  }),

  components: {
    Button,
    Checkbox
  },

  computed: {
    ...mapGetters(['getAppLanguage'])
  },

  async mounted() {
    if (this.$route.query.lang) this.setAppLanguage(this.$route.query.lang)

    if (await this.$user.uid)
      this.$router.replace('/document/loading...', () => {})
  },

  methods: {
    ...mapActions(['loginAction', 'setAppLanguage']),

    /**
     *
     */
    getCheckboxState(checkboxState) {
      this.rememberMe = checkboxState
    },

    /**
     *
     */
    resetValidationErrors() {
      this.validationErrors = []
    },

    /**
     *
     */
    validateLogin() {
      // Clear the errors before we validate again
      this.resetValidationErrors()

      // email validation
      if (!this.email) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].logIn.errors.email.empty
        )
      } else {
        if (/.+@.+/.test(this.email) !== true) {
          this.validationErrors.push(
            this.$languages[this.getAppLanguage].logIn.errors.email.invalid
          )
        }
      }

      // password validation
      if (!this.password) {
        this.validationErrors.push(
          this.$languages[this.getAppLanguage].logIn.errors.password.empty
        )
      } else {
        if (/.{6,}/.test(this.password) !== true) {
          this.validationErrors.push(
            this.$languages[this.getAppLanguage].logIn.errors.password.invalid
          )
        }
      }

      // when valid then sign in
      if (!this.validationErrors.length) {
        this.login()
      }
    },

    /**
     *
     */
    login() {
      this.loginAction({
        email: this.email,
        password: this.password,
        rememberMe: this.rememberMe
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login__loader {
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--size-unit-half);
  background: linear-gradient(
    90deg,
    var(--color-main-dark) 0%,
    var(--color-main-light) 100%
  );
}

.login__errors {
  width: 100%;
  max-width: 440px;
  padding-bottom: var(--size-unit-3);
  color: var(--color-error);
}

.login__errors-reset {
  color: var(--color-main);
  text-decoration: underline;
  cursor: pointer;
}

.login__background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('../assets/auth-background-blurred.jpg');
  background-size: cover;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(
      var(--color-brand-darkest-rgb),
      var(--opacity-overlay)
    );
  }
}

.login__form {
  @include form;
}

.login__form-checkbox-label {
  display: flex;
  cursor: pointer;

  input[type='checkbox'] {
    margin: var(--size-unit-half-2) var(--size-unit) 0 0;
  }
}

.login__form-checkbox-forgot-password-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: var(--size-unit-2) 0;

  label {
    flex: 1;
  }

  @include screen-medium {
    flex-direction: row;
  }
}

.login__form-forgot-password {
  display: inline-flex;
  min-width: 180px;
  align-items: center;
  padding-bottom: 4px;
  margin-top: var(--size-unit-3);

  @include screen-medium {
    margin-top: 0;
    justify-content: flex-end;
  }
}

.login__form-divider {
  margin: var(--size-unit) 0 var(--size-unit-2);
  height: 1px;
  background-color: var(--color-theme-lighter);
}

.login__form-step {
  @include hide-scrollbar;
  overflow-y: scroll;
  width: 100%;
  max-width: 440px;
  height: 100%;
  padding-bottom: var(--size-unit-10);

  img {
    margin-bottom: var(--size-unit);
    width: 50%;
    min-width: 180px;
  }

  @include screen-medium {
    height: initial;

    img {
      margin-bottom: var(--size-unit-5);
    }
  }
}

.login__form-title {
  margin: var(--size-unit-2) 0;
}

::-webkit-scrollbar {
  display: none;
}
</style>
